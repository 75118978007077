import { h, Component } from 'preact';
import { route } from 'preact-router';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';
import fireauth from '../../store/fireauth';




class Auth extends Component {

  componentDidMount() {
    fireauth.login_callback = (user) => {
      console.log("You logged in as " + user.email);
      
      this.props.check_authorization();
      this.props.load_userdata();
      route('/');
    }
    fireauth.logout_callback = () => {
      console.log("You logged out.");
      this.props.check_authorization();
      route('/login');
    }
    this.props.check_authorization();
  }

  componentDidUpdate(prevProps, prevStat) {
    // if(!this.props.signedIn){
    //   route('/');
    // }
  }

  // first props, second state
  render({ signedIn, signIn }, { }) {
    return (
      <section class="hidden auth"></section>
    );
  }
}

export default connect('signedIn', actions)(Auth)