import { h, Component } from 'preact';
import { Link } from 'preact-router';
import './style.css';

import MenuPanel from "../menupanel";


class Navbar extends Component {

    renderItemLeft() {
        switch (this.props.type) {
            case 'menu':
                return (<MenuPanel></MenuPanel>);
            case 'back':
                return (<Link class="button_menu btn" href="/"><i class="material-icons">keyboard_arrow_left</i></Link>);
            default:
                return null;
        }
    }

    render() {
        return (
            <header class="navbar">
                <section class="navbar_section">
                    {this.renderItemLeft()}
                </section>
            </header>
        );
    }

}

export default Navbar;

