import { h, Component } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Login from '../routes/login';
import Search from '../routes/search';
import Stage from '../routes/stage';
import Auth from '../components/auth';

import { connect } from 'unistore/preact';
import actions from '../store/actions';

 class App extends Component {
  
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render() {
		return (
			<div id="app">
        <Auth></Auth>
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<Login path="/login" />
					<Search path="/search" />
					<Stage path="/stage" />
				</Router>
			</div>
		);
	}
}

export default connect('', actions)(App)