import { h, Component } from 'preact';
import './style.css';
import { Link } from 'preact-router';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';

import CategoryItem from '../../components/categoryitem';
import Navbar from '../../components/navbar';

import default_history from '../../data/history.js';


class Stage extends Component {
  constructor(props){
    super(props);
    // console.log(this.props);
    // this.state = {
    //   stage_value: parseInt(this.props.user_data.current_stage),
    // }
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  setStage(event){
    console.log("set stage to: " + this.state.stage_value);
    this.props.set_stage(this.state.stage_value);
  }

  handleSliderChange(event) {
    this.setState({
      ...this.state,
      stage_value: (parseInt(event.target.value) )
    });
  }

  renderCategories() {
    if (this.props.user_data != null) {
      let current_stage = this.state.stage_value;

      if(current_stage == null){
        current_stage = this.props.user_data.current_stage;

        this.setState({
          ...this.state,
          stage_value: current_stage
        });
      }  

      let categories = default_history[current_stage].Bausteine;

      return (
        <section className="overview__categories">
          {Object.keys(categories).map((category) => {
            return (
              <CategoryItem key={"category_" + category} name={category} amount={categories[category]} />
            )
          })}
        </section>);
    } else {
      return (
        //TODO: loading icon
        <section className="overview__categories">
          Loading...
      </section>);
    }
  }

  // first props, second state
  render({ }, { }) {
    return (
      <main >
      <Navbar type='menu' />
        <h3 className="slider__heading">Stufe {this.state.stage_value+1}</h3>
      <section className="slider_wrapper">
        <input className="slider" type="range" min="0" max="6" value={this.state.stage_value} onInput={this.handleSliderChange} />
        <Link className="btn btn-primary" href="/" onClick={this.setStage.bind(this)}>Auswählen</Link>
      </section>
      {this.renderCategories()}
    </main>
    );
  }
}

export default connect('user_data, user_history', actions)(Stage)