import { h, Component } from 'preact';
import style from './style.css';
import { Link } from 'preact-router';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';
import searchManager from '../../helpers/searchManager';

import Navbar from '../../components/navbar';
import FoodModal from '../../components/foodmodal';
import FoodItem from '../../components/fooditem';


class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_value: "",
      search_result: [],
      selected_item: {name: "", units: {"Gramm": 0}},
      modal_visible: false
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
  }

  handleSearchChange(event) {
    let self = this;
    this.setState({
      ...this.state,
      search_value: event.target.value
    }, ()=>{
      self.handleSearchSubmit.apply(self, [event]);
    });
  }
  handleSearchSubmit(event) {
    event.preventDefault();
    let search_result = searchManager.search(this.state.search_value);
    // search_result = search_result.map((item) => {
    //   return this.props.stats[item.ref]
    // })
    this.setState((state, props) => {
      return {
        ...state,
        search_result: search_result
      };
    });
    return false;
  }

  showModal(item) {
    var self = this;
    this.setState((state, props) => {
      return {
        ...state,
        selected_item: item,
      };
    }, ()=>{self.setState((state, props) => {
      return {
        ...state,
        modal_visible: true,
      };
    });});
  }
  submitModal(amount, unit){
    this.props.set_food_entry({name: this.state.selected_item.name, amount: amount, unit: unit});
    this.closeModal();
  }

  closeModal() {
    this.setState((state, props) => {
      return {
        ...state,
        modal_visible: false,
      };
    });
  }
  

  // first props, second state
  render({ }, { }) {
    return (
      <main class="search">
        <Navbar type='back'/>
        <form class="search_form form-group" onSubmit={this.handleSearchSubmit}>
          <div class="input-group">
            <input class="form-input input-lg" type="text" placeholder="Search..." value={this.state.search_value} onInput={this.handleSearchChange} />
            <button class="button_search btn input-group-btn btn-lg"><i class="material-icons" onClick={this.handleSearchSubmit}>search</i></button>
          </div>
        </form>
        <section class="foodlist">
          {
            this.state.search_result.map((item, index) => {
              return (<FoodItem key={"searchresult_" + index} category={item.category} name={item.name} onClick={()=>{this.showModal(item)}} />)
            })
          }
        </section>
        <FoodModal item={this.state.selected_item} active={this.state.modal_visible} onSubmit={this.submitModal} onClose={this.closeModal} />
      </main>
    );
  }
}

export default connect('stats, user_data, user_history', actions)(Search)