import './assets/css/spectre.min.css';
import './assets/css/spectre-exp.min.css';
import './assets/css/material_icons.css';
import './assets/css';

import App from './components/app';
import store from './store/store';
import actions from './store/actions';

import { h, render } from 'preact';
import { Provider} from 'unistore/preact'



render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.querySelector('body'));