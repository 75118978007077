import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


class Fireauth {
  constructor() {
    let that = this;
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        that.user = user;
        that.login_callback(user);
      } else {
        that.user = {};
        that.logout_callback();
      }
    });
  }

  user = {}
  login_callback = ()=>{console.log("login_callback")};
  logout_callback = ()=>{console.log("logout_callback")};

  signIn(email, password) {
    let that = this;
    return new Promise(
      function (resolve, reject) {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then(function (success) {
            that.user = success.user
            resolve(success);
          })
          .catch(function (error) {
            reject(error);
          });
      }
    );
  }

  signOut() {
    let that = this;
    return new Promise(
      function (resolve, reject) {
        firebase.auth().signOut()
          .then(function (success) {
            that.user = {}
            resolve(success);
          })
          .catch(function (error) {
            reject(error);
          });
      }
    );
  }

  signUp(email, password){
    let that = this;
    return new Promise(
      function (resolve, reject) {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then(function (success) {
            that.signIn(email, password).then(()=>{
              resolve(success);
            })
          })
          .catch(function (error) {
            reject(error);
          });
      }
    );
    
  }
}

let fireauth = new Fireauth();
// fireauth.signOut();

export default fireauth;