import createStore from 'unistore';
import devtools from 'unistore/devtools';
import stats from '../data/stats';


let initialState = {
  signedIn: false,
  signIn_message: "",
  stats: stats,
}

let store;

if (process.env.NODE_ENV === 'production') {
  store = createStore(initialState);
} else {
  store = devtools(createStore(initialState));
}

export default store;