import db_collection from './custom_firestore';
import fireauth from './fireauth';

import stats from '../data/stats.js';
import default_user from '../data/user.js';
import default_history from '../data/history.js';


let actions = store => ({
  check_authorization(state) {
    return {
      ...state,
      signedIn: fireauth.user.uid != null,
    };
  },

  signIn(state, email, password) {
    fireauth.signIn(email, password)
      .then(success => {
        store.setState({
          ...state,
          signedIn: true,
          signIn_message: "",
        });
      })
      .catch(error => {
        store.setState({
          ...state,
          signedIn: false,
          signIn_message: error.message,
        });
      });
  },

  signOut(state) {
    fireauth.signOut()
      .then(success => {
        store.setState({
          ...state,
          signedIn: false,
          signIn_message: "You successfully logged out.",
        });
      })
      .catch(error => {});
  },

  signUp(state, email, password) {
    fireauth.signUp(email, password)
      .then(success => {
        db_collection.doc("users").collection("collection").doc(fireauth.user.uid).set(default_user)
          .catch(function (error) {});
        store.setState({
          ...state,
          signIn_message: "Signup successful",
        });
      })
      .catch(error => {
        store.setState({
          ...state,
          signIn_message: error.message,
        });
      });
  },

  load_userdata(state) {
    let currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");

    db_collection.doc("users").collection("collection").doc(fireauth.user.uid).get()
      .then((userdata) => {
        userdata = userdata.data();
        store.setState({
          ...state,
          user_data: userdata,
        });

        db_collection.doc("users").collection("collection").doc(fireauth.user.uid).collection("history").doc(currentDate).get()
          .then((history) => {
            let history_id = history.id;
            history = history.data();
            if (history == null) {
              history = default_history[userdata.current_stage];
              history.datum = currentDate;
            }

            store.setState({
              ...state,
              user_history: {
                ...state.user_history,
                [history_id]: history,
              }
            })
          });
      });

  },

  set_history(state) {
    db_collection.doc("users").collection("collection").doc(fireauth.user.uid).collection("history").doc(default_history.datum).set(default_history)
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

  },

  set_food_entry(state, entry) {
    let currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    let currentEntry = state.user_history[currentDate];

    if (currentEntry == null) {
      currentEntry = {
        ...default_history[state.user_data.current_stage]
      };
      currentEntry.datum = currentDate;
    }

    if (entry != null) {
      currentEntry.Lebensmittel.push({
        name: entry.name,
        amount: entry.amount,
        unit: entry.unit
      });

      let foodItem = stats.filter(item => item.name === entry.name)[0];
      let bausteine = entry.amount / (foodItem.units[entry.unit]);
      currentEntry.Bausteine[foodItem.category] -= bausteine;
    }

    db_collection.doc("users").collection("collection").doc(fireauth.user.uid).collection("history").doc(currentEntry.datum).set(currentEntry)
      .then(function (docRef) {
        console.log("Saved new entry to Firestore");
      })
      .catch(function (error) {
        console.error("Error saving new entry to Firestore: ", error);
      });


    return {
      ...state,
      user_history: {
        ...state.user_history,
        [currentDate]: currentEntry
      }
    }
  },

  set_stage(state, next_stage) {
    //adapt today value to the stage
    let currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    let currentEntry = state.user_history[currentDate];
    if (currentEntry == null) {
      currentEntry = default_history[state.user_data.current_stage]

      currentEntry.datum = currentDate;
    }

    let currentStage = default_history[state.user_data.current_stage];
    let nextStage = default_history[next_stage];
    let newEntry = {
      ...currentEntry
    };
    Object.keys(currentEntry.Bausteine).forEach((category) => {
      let difference = nextStage.Bausteine[category] - currentStage.Bausteine[category];
      newEntry.Bausteine[category] = currentEntry.Bausteine[category] + difference;
    })


    let newState = {
      ...state,
      user_history: {
        ...state.user_history,
        [currentDate]: newEntry
      },

      user_data: {
        ...state.user_data,
        current_stage: next_stage
      }
    };

    db_collection.doc("users").collection("collection").doc(fireauth.user.uid).collection("history").doc(newEntry.datum).set(newEntry)
      .then(function (docRef) {
        console.log("Saved new entry to Firestore");
      })
      .catch(function (error) {
        console.error("Error saving new entry to Firestore: ", error);
      });

    db_collection.doc("users").collection("collection").doc(fireauth.user.uid).set(newState.user_data);

    return newState;
  }

})


export default actions;