import firebase from '@firebase/app';
import 'firebase/firestore';

let collection_id = "food_diary"

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyCBR7nYYB2kZUs72SsJX-MdBzu6ZDWSXRY',
  authDomain: 'niewstorage.firebaseapp.com',
  projectId: 'niewstorage'
});

// firebase.firestore().enablePersistence()
//   .catch(function(err) {
//     console.error(err)
//       if (err.code == 'failed-precondition') {
//           // Multiple tabs open, persistence can only be enabled
//           // in one tab at a a time.
//           // ...
//           alert("Please close all the other tabs");
//       } else if (err.code == 'unimplemented') {
//           // The current browser does not support all of the
//           // features required to enable persistence
//           // ...
//           console.log("persistence is not implemented for this browser")
//       }
//   });
// Subsequent queries will use persistence, if it was enabled successfully

var db = firebase.firestore();


export default db.collection(collection_id);