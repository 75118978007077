import { h, Component } from 'preact';
import './style.css';


class CategoryItem extends Component {
  render() {
    return (
      <div class="category_item card">
        <div class="card__image">
          <img class="card__image_item img-responsive" alt={"Category " + this.props.name} src={"/img/" + this.props.name.toLowerCase() + ".jpg"} />
        </div>
        <div class="card__header">
          <div class="card__title">{this.props.name.replace("ue", "ü").replace("ae", "ä")}</div>
          <div class="card__value">{this.props.amount}</div>
        </div>
      </div>
    );
  }

}

export default CategoryItem;

