import { h, Component } from 'preact';
import './style.css';


class FoodItem extends Component {
    render() {
        return (
          <div class="food_item card" onClick={this.props.onClick}>
          <div class="card-image">
              <img alt={"Category " + this.props.category} src={"/img/" + this.props.category.toLowerCase() + ".jpg"} class="img-responsive" />
          </div>
          <div class="card-header">
              <div class="card-title">{this.props.name.replace("ue", "ü").replace("ae", "ä")}</div>
          </div>
      </div>
        );
    }

}

export default FoodItem;

