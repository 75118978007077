export default [
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 3,
          "Milch": 2,
          "Fett": 2,
          "Fleisch": 2,
          "Obst": 2,
          "Extra": 0,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 4,
          "Milch": 2,
          "Fett": 2,
          "Fleisch": 2,
          "Obst": 2,
          "Extra": 1,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 4,
          "Milch": 3,
          "Fett": 3,
          "Fleisch": 2,
          "Obst": 2,
          "Extra": 1,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 5,
          "Milch": 3,
          "Fett": 3,
          "Fleisch": 2.5,
          "Obst": 2,
          "Extra": 1,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 5,
          "Milch": 3,
          "Fett": 4,
          "Fleisch": 2.5,
          "Obst": 2,
          "Extra": 2,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 6,
          "Milch": 3,
          "Fett": 4,
          "Fleisch": 2.5,
          "Obst": 3,
          "Extra": 2,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
  {
      "datum": "00000000",
      "Bausteine": {
          "Getreide": 7,
          "Milch": 3,
          "Fett": 4,
          "Fleisch": 2.5,
          "Obst": 3,
          "Extra": 3,
          "Gemuese": 3,
          "Getraenke": 8
      },
      "Lebensmittel": []
  },
]