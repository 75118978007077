import { h, Component } from 'preact';
import { route } from 'preact-router';
import './style.css';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';



class Login extends Component {

  componentDidMount() {
  }

  signIn(){
    let formdata = new FormData(document.getElementById('login__form'));
    let data = {};
    formdata.forEach((value, key) => {data[key] = value});
    // this.props.signIn("ec.tom.n@gmail.com", "123456")
    this.props.signIn(data.email, data.password);
  }

  signUp(){
    let formdata = new FormData(document.getElementById('login__form'));
    let data = {};
    formdata.forEach((value, key) => {data[key] = value});
    // this.props.signIn("ec.tom.n@gmail.com", "123456")
    this.props.signUp(data.email, data.password);
  }

  // first props, second state
  render({ signIn_message }, { }) {
    return (
      <main class="login">
        <div class="login__wrapper">
          <form id="login__form" class="form-group login__form">
            <label class="form-label" for="email_input">E-Mail</label>
            <input class="form-input" type="email" id="email_input" name="email" placeholder="E-Mail" />
            <label class="form-label" for="password_input">Password</label>
            <input class="form-input" type="password" id="password_input" name="password" placeholder="Password" />
          </form>
          <div class="message">{signIn_message}</div>
          <button class="login__button btn btn-primary" onClick={this.signIn.bind(this)}>Login</button>
          <button class="signup__button btn" onClick={this.signUp.bind(this)}>Sign Up</button>
        </div>

      </main>
    );
  }
}

export default connect('signedIn,signIn_message', actions)(Login)