export default [{
  "category": "Getreide",
  "name": "Vollkornbrot",
  "units": {
    "Gramm": 50,
    "Scheibe(n)": 1
  }
}, {
  "category": "Getreide",
  "name": "Vollkorntoast",
  "units": {
    "Gramm": 60,
    "Scheibe(n)": 2
  }
}, {
  "category": "Getreide",
  "name": "Toast",
  "units": {
    "Gramm": 45,
    "Scheibe(n)": 1.5
  }
}, {
  "category": "Getreide",
  "name": "Kleines Vollkornhörnchen",
  "units": {
    "Gramm": 60,
    "Stück": 1
  }
}, {
  "category": "Getreide",
  "name": "Weizen-/Roggenbrötchen",
  "units": {
    "Gramm": 45,
    "Stück": 1
  }
}, {
  "category": "Getreide",
  "name": "Ciabattabrot",
  "units": {
    "Gramm": 45,
    "Stück": 1
  }
}, {
  "category": "Getreide",
  "name": "Grissinni",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Getreide",
  "name": "Laugenbrezel",
  "units": {
    "Gramm": 50,
    "Stück": 1
  }
}, {
  "category": "Getreide",
  "name": "Knäckebrot, Zwieback ",
  "units": {
    "Gramm": 40,
    "Scheibe(n)": 4
  }
}, {
  "category": "Getreide",
  "name": "Früchtemüsli",
  "units": {
    "Gramm": 30,
    "EL": 2
  }
}, {
  "category": "Getreide",
  "name": "Cornflakes",
  "units": {
    "Gramm": 30,
    "EL": 7
  }
}, {
  "category": "Getreide",
  "name": "Haferflocken",
  "units": {
    "Gramm": 30,
    "EL": 3
  }
}, {
  "category": "Getreide",
  "name": "Hirseflocken, Buchweizenflocken",
  "units": {
    "Gramm": 30,
    "EL": 3
  }
}, {
  "category": "Getreide",
  "name": "Vollkornmehl",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Weizenmehl Typ 405",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Maismehl, Reismehl, Buchweizenmehl",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Stärke",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Guarkernmehl",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Johannisbrotkernmehl",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Kartoffelstärke",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Mais",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Getreide",
  "name": "Maisstärke",
  "units": {
    "Gramm": 40,
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Kartoffeln",
  "units": {
    "Gramm, gekocht": 200,
    "Gramm, roh": 200
  }
}, {
  "category": "Getreide",
  "name": "Vollkornreis",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Reis",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Vollkornnudeln",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Buchweizenvollkornnudeln",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Hirsenudeln",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Amaranth-, Quinoanudeln",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Nudeln, Spätzle",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 50
  }
}, {
  "category": "Getreide",
  "name": "Schupfnudeln",
  "units": {
    "Gramm, gekocht": 150,
    "Gramm, roh": 150
  }
}, {
  "category": "Getreide",
  "name": "Glasnudeln",
  "units": {
    "Gramm, gekocht": 150
  }
}, {
  "category": "Getreide",
  "name": "Weizen, Polenta",
  "units": {
    "Gramm, trocken": 30
  }
}, {
  "category": "Getreide",
  "name": "Hirse",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Getreide",
  "name": "Grieß",
  "units": {
    "Gramm, trocken": 30
  }
}, {
  "category": "Getreide",
  "name": "Grünkern",
  "units": {
    "Gramm": 40
  }
}, {
  "category": "Getreide",
  "name": "Hartweizengrieß",
  "units": {
    "EL, gehäuft": 2
  }
}, {
  "category": "Getreide",
  "name": "Biovollkornreiswaffeln",
  "units": {
    "Gramm": 25
  }
}, {
  "category": "Getreide",
  "name": "Dinkelgetreide",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Getreide",
  "name": "Dinkelmilch",
  "units": {
    "Milliliter ": 250
  }
}, {
  "category": "Getreide",
  "name": "Maronen",
  "units": {
    "Gramm": 50,
    "Stück": 10
  }
}, {
  "category": "Getreide",
  "name": "Hefekloß",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Getreide",
  "name": "Kartoffelkloß",
  "units": {
    "Gramm": 100,
    "Stück": 1
  }
}, {
  "category": "Getreide",
  "name": "Milchreis",
  "units": {
    "Gramm, trocken": 30
  }
}, {
  "category": "Getreide",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Milch",
  "name": "Actimel",
  "units": {
    "Gramm": 125,
    "Flasche": 1
  }
}, {
  "category": "Milch",
  "name": "Buko Frischkäse (Rahmstufe, 55% F.i.Tr.)",
  "units": {
    "Gramm": 40
  }
}, {
  "category": "Milch",
  "name": "Buttermilch, natur",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Cappuccino (Fertigprodukt), ungezuckert",
  "units": {
    "Tasse(n)": 2
  }
}, {
  "category": "Milch",
  "name": "Dickmilch, 1,5% Fett",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Milch",
  "name": "Dickmilch, 3,5% Fett",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Milch",
  "name": "Feta, fettreduziert",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Milch",
  "name": "Frischkäse, fettreduziert",
  "units": {
    "Gramm": 30,
    "EL, gestrichen": 2
  }
}, {
  "category": "Milch",
  "name": "Geriebener Käse (< 30% F.i.Tr.)",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Milch",
  "name": "Graukäse",
  "units": {
    "Gramm": 30,
    "Scheibe(n)": 1
  }
}, {
  "category": "Milch",
  "name": "Harzerkäse",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Milch",
  "name": "Haselnuss-, Kokosmilch",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Hüttenkäse",
  "units": {
    "Gramm": 80,
    "EL": 2
  }
}, {
  "category": "Milch",
  "name": "Joghurt (1,5% Fett), natur",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Milch",
  "name": "Käse (< 30% F.i.Tr.)",
  "units": {
    "Gramm": 30,
    "Scheibe(n)": 1
  }
}, {
  "category": "Milch",
  "name": "Käse (< 45% F.i.Tr.)",
  "units": {
    "Gramm": 20,
    "Scheibe(n)": 1
  }
}, {
  "category": "Milch",
  "name": "Kefir (1,5% Fett), natur",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Laktosefreie Milch, Soja-, Hafermilch",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Magerquark, natur ",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Milch",
  "name": "Mandelmilch",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Milch (1,5% Fett)",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Molke, natur",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Milch",
  "name": "Mozzarella, fettreduziert",
  "units": {
    "Gramm": 62,
    "Stück": 0.5
  }
}, {
  "category": "Milch",
  "name": "Saure Sahne ",
  "units": {
    "Gramm": 60,
    "EL": 4
  }
}, {
  "category": "Milch",
  "name": "Sojadrink, natur",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Sojajoghurt",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Milch",
  "name": "Sojajoghurt, natur",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Milch",
  "name": "Sojamilch, natur",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Milch",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Fleisch",
  "name": "Austern, ausgelöst",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Brathähnchen ohne Haut gegrillt (Broiler)",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Fleisch",
  "name": "Bratwurst, Currywurst zu Hause zubereitet ",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Cabanossi",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Ei",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Fleisch",
  "name": "Fette Wurst (z.B. Salami)",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Fisch, fettarm (z.B. Seelachs, Rotbarsch, Kabeljau)",
  "units": {
    "Gramm, roh": 150
  }
}, {
  "category": "Fleisch",
  "name": "Fisch, fettreich (Lachs, Räucherfisch, Thunfisch, Hering)",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Fleisch",
  "name": "Fischstäbchen (auch glutenfreie)",
  "units": {
    "Stück": 2
  }
}, {
  "category": "Fleisch",
  "name": "Frikadelle (klein)",
  "units": {
    "Gramm": 1001
  }
}, {
  "category": "Fleisch",
  "name": "Garnele, frisch",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Fleisch",
  "name": "Hackfleisch (gemischt)",
  "units": {
    "Gramm, roh ": 50
  }
}, {
  "category": "Fleisch",
  "name": "Hülsenfrüchte (z.B Kidneybohnen, Linsen)",
  "units": {
    "Gramm": 40
  }
}, {
  "category": "Fleisch",
  "name": "Hülsenfrüchte (z.B Kidneybohnen, Linsen), aus der Dose",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Fleisch",
  "name": "Hummer, ausgelöst",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Fleisch",
  "name": "Jap. Tofubratfilet",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fleisch",
  "name": "Käsekrainer (fette Wurst und Käse)",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Krabben (ohne Schale)",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Krebs, Languste ",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Fleisch",
  "name": "Leber",
  "units": {
    "Gramm": 80
  }
}, {
  "category": "Fleisch",
  "name": "Leberkäse",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Leberknödel",
  "units": {
    "Gramm": 100,
    "Stück ": 1
  }
}, {
  "category": "Fleisch",
  "name": "Magere Wurst (z.B. Gekochter Schinken)",
  "units": {
    "Gramm": 60
  }
}, {
  "category": "Fleisch",
  "name": "Mageres Fleisch (z.B. Pute), natur",
  "units": {
    "Gramm, roh": 100
  }
}, {
  "category": "Fleisch",
  "name": "Markklößchen",
  "units": {
    "Stück": 1,
    "Gramm": 25
  }
}, {
  "category": "Fleisch",
  "name": "Miesmuscheln",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Roulade",
  "units": {
    "Gramm": 75,
    "Stück": 0.5
  }
}, {
  "category": "Fleisch",
  "name": "Sojahack",
  "units": {
    "Gramm": 35
  }
}, {
  "category": "Fleisch",
  "name": "Sojawurst/Tofuwurst",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Fleisch",
  "name": "Steckmuscheln",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Thunfisch (in Wasser abgetropft)",
  "units": {
    "Gramm": 75,
    "Dose(n)": 0.5
  }
}, {
  "category": "Fleisch",
  "name": "Tintenfisch ",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Fleisch",
  "name": "Tofu",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Fleisch",
  "name": "Veg. Bratwurst",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fleisch",
  "name": "Veg. Brotaufstrich/veggi Leberwurst",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fleisch",
  "name": "Veg. Fischstäbchen aus Weizenfasern und Sojabohnen",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fleisch",
  "name": "Veg. Fleisch und Knackwurst",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fleisch",
  "name": "Veg. Roulade aus Weizeneiweiß",
  "units": {
    "Gramm": 60
  }
}, {
  "category": "Fleisch",
  "name": "Veggi Fischfilet aus Weizenfasern, Sojabohnen ",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Fleisch",
  "name": "Weizeneiweißbrötchen",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fleisch",
  "name": "Wiener Würstchen (Geflügel)",
  "units": {
    "Gramm": 60
  }
}, {
  "category": "Fleisch",
  "name": "Wiener Würstchen (Schwein)",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fett",
  "name": "Avocado",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fett",
  "name": "Blätterteig",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Fett",
  "name": "Croissant",
  "units": {
    "Gramm": 50,
    "Stück": 0.7,
  }

}, {
  "category": "Fett",
  "name": "Butter",
  "units": {
    "EL": 1,
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Crème fraîche",
  "units": {
    "EL": 2,
    "Gramm": 30
  }
}, {
  "category": "Fett",
  "name": "Gemahlene Nüsse ",
  "units": {
    "EL, gestrichen": 2,
    "Gramm": 20
  }
}, {
  "category": "Fett",
  "name": "Kaffeesahne, 10% Fett",
  "units": {
    "EL": 8
  }
}, {
  "category": "Fett",
  "name": "Kokosmilch, cremig",
  "units": {
    "EL": 4,
    "Gramm": 60
  }
}, {
  "category": "Fett",
  "name": "Kokosnuss",
  "units": {
    "Gramm": 30
  }
}, {
  "category": "Fett",
  "name": "Kürbiskerne",
  "units": {
    "EL": 1,
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Leinsamen geschrotet",
  "units": {
    "EL": 2,
    "Gramm": 20
  }
}, {
  "category": "Fett",
  "name": "Mandeln",
  "units": {
    "Stück": 10,
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Mandelsahne",
  "units": {
    "Milliliter": 100
  }
}, {
  "category": "Fett",
  "name": "Margarine",
  "units": {
    "EL": 1,
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Mayonnaise",
  "units": {
    "TL": 1,
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Nüsse (10 Haselnüsse, 4 Walnusskerne)",
  "units": {
    "Gramm": 15
  }
}, {
  "category": "Fett",
  "name": "Öl (Raps-, Oliven-, Walnuss-, Leinöl) naturbelassen, kaltgepresst",
  "units": {
    "EL": 1,
    "Gramm": 12
  }
}, {
  "category": "Fett",
  "name": "Oliven, grün ",
  "units": {
    "Stück": 20,
    "Gramm": 60
  }
}, {
  "category": "Fett",
  "name": "Oliven, schwarz",
  "units": {
    "Stück": 10,
    "Gramm": 30
  }
}, {
  "category": "Fett",
  "name": "Pflanzliche Sahne (Rama)",
  "units": {
    "EL": 2
  }
}, {
  "category": "Fett",
  "name": "Salatdressing mit Öl",
  "units": {
    "EL": 2
  }
}, {
  "category": "Fett",
  "name": "Schlagsahne",
  "units": {
    "EL": 2,
    "Gramm": 30
  }
}, {
  "category": "Fett",
  "name": "Schmalz",
  "units": {
    "EL": 1
  }
}, {
  "category": "Fett",
  "name": "Sonnenblumenkerne",
  "units": {
    "EL": 1,
    "Gramm": 20
  }
}, {
  "category": "Fett",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Obst",
  "name": "Ananas",
  "units": {
    "Scheibe(n)": 2,
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Apfel",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Obst",
  "name": "Apfelmus, ungezuckert",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Apfikosen, frisch",
  "units": {
    "Stück": 2,
    "Gramm": 100
  }
}, {
  "category": "Obst",
  "name": "Aprikosen, getrocknet",
  "units": {
    "Stück": 3,
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Banane",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Obst",
  "name": "Beerenobst",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Birne",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Obst",
  "name": "Blutorange",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Obst",
  "name": "Datteln, getrocknet",
  "units": {
    "Stück": 3,
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Erdbeeren",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Feigen, frisch",
  "units": {
    "Stück": 2,
    "Gramm": 150
  }
}, {
  "category": "Obst",
  "name": "Feigen, getrocknet",
  "units": {
    "Stück ": 1,
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Grapefruit",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Obst",
  "name": "Honigmelone",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Obst",
  "name": "Kiwi",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Obst",
  "name": "Mandarine",
  "units": {
    "Stück": 2
  }
}, {
  "category": "Obst",
  "name": "Mango",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Obst",
  "name": "Obstsaft",
  "units": {
    "Milliliter ": 125
  }
}, {
  "category": "Obst",
  "name": "Orange",
  "units": {
    "Stück": 1
  }
}, {
  "category": "Obst",
  "name": "Pfirsich/ Nektarine ",
  "units": {
    "Stück": 1,
    "Gramm": 100
  }
}, {
  "category": "Obst",
  "name": "Pflaumen",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Pflaumen, frisch ",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Pflaumen, getrocknet",
  "units": {
    "Stück": 2,
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Rosinen, getrocknet",
  "units": {
    "EL": 2,
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Obst",
  "name": "Süsskirsch, getrocknet",
  "units": {
    "Gramm": 25
  }
}, {
  "category": "Obst",
  "name": "Süßkirschen, frisch",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Waldbeeren",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Obst",
  "name": "Wassermelone",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Obst",
  "name": "Weintrauben",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Gemuese",
  "name": "Aubergine",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Blattspinat (TK)",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Bohnen, grün ",
  "units": {
    "Gramm": 150
  }
}, {
  "category": "Gemuese",
  "name": "Brokkoli",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Champignons",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Chicorée",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Gemuese",
  "name": "Endivie",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Gemuese",
  "name": "Erbsen, grün",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Gemuese",
  "name": "Fenchel",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Gemüsemischung",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Gemüsesaft",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Gemuese",
  "name": "Getrocknete Tomate ohne Öl ",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Gemuese",
  "name": "Gewürzgurken",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Gurken",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Hokkaido, Kürbis ",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Kohlrabi",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Kopfsalat",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Gemuese",
  "name": "Lauch, Porree ",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Mairübchen",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Möhre",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Paprika",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Pastinake",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Rosenkohl",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Rote Bete",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Rucola",
  "units": {
    "Gramm": 75
  }
}, {
  "category": "Gemuese",
  "name": "Sauerkraut, roh",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Schwarzwurzel",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Gemuese",
  "name": "Spargel",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Tomate roh",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Zucchini",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Gemuese",
  "name": "Zwiebeln",
  "units": {
    "Gramm": 200
  }
}, {
  "category": "Getraenke",
  "name": "Diät-/ Lightgetränke",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Früchtetee, ungezuckert, Aufguss",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Kaffee",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Kaffee, frischer Aufguss",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Kräutertee, ungezuckert, Aufguss ",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Mineralwasser",
  "units": {
    "Milliliter": 250
  }
}, {
  "category": "Getraenke",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Extra",
  "name": "Aachener Printen",
  "units": {
    "Stück": 1,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Anisplätzchen",
  "units": {
    "Stück": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Apfelmus, gezuckert",
  "units": {
    "Gramm": 125
  }
}, {
  "category": "Extra",
  "name": "Baseler Leckerli",
  "units": {
    "Stück": 1,
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Bier, auch glutenfrei",
  "units": {
    "Milliliter": 200
  }
}, {
  "category": "Extra",
  "name": "Bonbon, auch laktosefrei",
  "units": {
    "Stück": 5
  }
}, {
  "category": "Extra",
  "name": "Bratensoße, auch glutenfrei",
  "units": {
    "EL": 8,
    "Gramm": 120
  }
}, {
  "category": "Extra",
  "name": "Buttergebäck",
  "units": {
    "Stück": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Butterkekse",
  "units": {
    "Stück": 5,
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Buttertorte",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Extra",
  "name": "Chilisauce",
  "units": {
    "EL": 9,
    "Milliliter": 150
  }
}, {
  "category": "Extra",
  "name": "Chips, Flis, auch glutenfrei",
  "units": {
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Cocktails",
  "units": {
    "Milliliter": 100
  }
}, {
  "category": "Extra",
  "name": "Diabetikergummibärchen",
  "units": {
    "Stück": 20,
    "Gramm": 40
  }
}, {
  "category": "Extra",
  "name": "Dominosteine",
  "units": {
    "Stück": 2,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Dresdner Stollen",
  "units": {
    "Stück": 0.5,
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Eis, auch laktosefrei",
  "units": {
    "kleine Kugel": 1,
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Elisenlebkuchen",
  "units": {
    "Stück": 0.5,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Früchtebrot (Rührteig)",
  "units": {
    "Scheibe": 0.5,
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Glühwein",
  "units": {
    "Glas": 1,
    "Milliliter": 200
  }
}, {
  "category": "Extra",
  "name": "Gummibären",
  "units": {
    "Stück": 15,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Heidesand",
  "units": {
    "Stück": 3,
    "Gramm": 18
  }
}, {
  "category": "Extra",
  "name": "Honig",
  "units": {
    "TL": 4,
    "Gramm": 40
  }
}, {
  "category": "Extra",
  "name": "Honigkuchen",
  "units": {
    "Stück": 0.5,
    "Gramm": 35
  }
}, {
  "category": "Extra",
  "name": "Kakaopulver",
  "units": {
    "EL": 2,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Kuchen, fettarm (Hefe, Biskuit, Obst)",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Extra",
  "name": "Lakritz",
  "units": {
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Lebkuchen",
  "units": {
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Limonade",
  "units": {
    "Glas": 1,
    "Milliliter": 200
  }
}, {
  "category": "Extra",
  "name": "Löffelbisquit",
  "units": {
    "Stück": 5
  }
}, {
  "category": "Extra",
  "name": "Makronen",
  "units": {
    "Stück": 2,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Mandelmakronen",
  "units": {
    "Stück": 3,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Marmelade",
  "units": {
    "TL": 4,
    "Gramm": 40
  }
}, {
  "category": "Extra",
  "name": "Marzipanstollen (Hefeteig)",
  "units": {
    "Stück": 0.5,
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Mohnstollen (Hefeteig)",
  "units": {
    "Stück": 0.5,
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Nürnberger Lebkuchen",
  "units": {
    "Stück": 0.5,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Nuss-Nougat Creme, auch laktosefrei",
  "units": {
    "TL": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Nussplätzchen",
  "units": {
    "Stück": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Pfefferkuchen",
  "units": {
    "Stück": 3,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Pfefferkuchenherz (ungefüllt)",
  "units": {
    "Stück": 5,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Pfeffernüsse",
  "units": {
    "Stück": 4,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Plumpudding",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Popcorn, süß",
  "units": {
    "Gramm": 40
  }
}, {
  "category": "Extra",
  "name": "Praline, auch glutenfrei und laktosefrei",
  "units": {
    "Stück": 2,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Quarkstollen",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Extra",
  "name": "Sahnesoße, auch laktosefrei",
  "units": {
    "EL": 2,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Salzgebäck, auch glutenfrei",
  "units": {
    "Gramm": 50
  }
}, {
  "category": "Extra",
  "name": "Schokolade, auch laktosefrei",
  "units": {
    "Stück": 3,
    "Gramm": 18
  }
}, {
  "category": "Extra",
  "name": "Schwarz-Weiß-Gebäck",
  "units": {
    "Stück": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Sekt",
  "units": {
    "Milliliter": 100
  }
}, {
  "category": "Extra",
  "name": "Sonstiges",
  "units": {
    "Einheit(en)": 1
  }
}, {
  "category": "Extra",
  "name": "Spekulatius",
  "units": {
    "Stück": 3,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Spirituosen",
  "units": {
    "Milliliter": 20
  }
}, {
  "category": "Extra",
  "name": "Spitzbube (Mürbeteig)",
  "units": {
    "Stück": 1,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Springerle",
  "units": {
    "Stück": 3,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Spritzgebäck",
  "units": {
    "Stück": 2,
    "Gramm": 20
  }
}, {
  "category": "Extra",
  "name": "Teilchen, fettarm (Amerikaner, Rosinenchnecke)",
  "units": {
    "Stück": 0.5
  }
}, {
  "category": "Extra",
  "name": "Tomatenketchup",
  "units": {
    "EL": 4,
    "Gramm": 80
  }
}, {
  "category": "Extra",
  "name": "Vanillekipferl",
  "units": {
    "Stück": 3,
    "Gramm": 24
  }
}, {
  "category": "Extra",
  "name": "Vanillepuddingpulver",
  "units": {
    "Gramm": 100
  }
}, {
  "category": "Extra",
  "name": "Wein",
  "units": {
    "Milliliter": 125
  }
}, {
  "category": "Extra",
  "name": "Wespennest",
  "units": {
    "Stück": 1,
    "Gramm": 25
  }
}, {
  "category": "Extra",
  "name": "Zimtsterne",
  "units": {
    "Stück": 2,
    "Gramm": 30
  }
}, {
  "category": "Extra",
  "name": "Zucker",
  "units": {
    "TL": 3,
    "Gramm": 24
  }
}]