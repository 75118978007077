import { h, Component } from 'preact';
import { Link } from 'preact-router';
import './style.css';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';



class MenuPanel extends Component {
  render() {
    return (
      <div class="off-canvas menu_panel">
        <a class="off-canvas-toggle btn button_menu" href="#menu_panel__content">
          <i class="icon material-icons">menu</i>
        </a>

        <div id="menu_panel__content" class="off-canvas-sidebar">
          <h3 class="logo"> <img src="/img/logo_monochrome.svg" alt=""/></h3>
          <ul class="nav">
            <li class={"nav-item" + ((this.props.active === "home") ? " active" : "")}>
              <Link href="/">Übersicht</Link>
            </li>
            {/* <li class={"nav-item" + ((this.props.active === "calendar") ? " active" : "")}>
              <Link href="/calendar">Kalender</Link>
            </li> */}
            <li class={"nav-item" + ((this.props.active === "stage") ? " active" : "")}>
              <Link href="/stage">Stufe</Link>
            </li>
            {/* <li class={"nav-item" + ((this.props.active === "settings") ? " active" : "")}>
              <Link href="/settings">Einstellungen</Link>
            </li> */}
          </ul>
          <a class="logout_button" onClick={()=>{this.props.signOut();}}>
            <i class="material-icons">exit_to_app</i>
            <span class="logout_button__text">Ausloggen</span>
          </a>
        </div>

        <a class="off-canvas-overlay" href="#"></a>
      </div>
    );
  }

}

export default connect('', actions)(MenuPanel)

