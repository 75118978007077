import { h, Component } from 'preact';
import './style.css';
import { Link } from 'preact-router';
import { connect } from 'unistore/preact';
import actions from '../../store/actions';

import Navbar from '../../components/navbar';
import CategoryItem from '../../components/categoryitem';


class Home extends Component {

  componentDidMount() {
  }

  renderCategories() {
    if (this.props.stats == null) {
      return (
        //TODO: loading icon
        <section class="overview">
          Lade Nahrungsmittel Details...
      </section>);
    } else if(this.props.user_history == null){
      return (
        //TODO: loading icon
        <section class="overview">
          Lade aktuelle Tagebuchdaten...
      </section>);
    } else{
      
      let currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
      let categories = this.props.user_history[currentDate].Bausteine;
      return (
        <section class="overview">
          {Object.keys(categories).map((category) => {
            return (
              <CategoryItem key={"category_" + category} name={category} amount={categories[category]} />
            )
          })}
        </section>);
    }
  }

  // first props, second state
  render({ }, { }) {
    return (
      <main >
        <Navbar type='menu'/>
        {this.renderCategories()}
        <footer>
          <Link class=" btn btn-action s-circle add_meal__button  " href="/search"><i class="material-icons">add</i></Link>
        </footer>
      </main>
    );
  }
}

export default connect('stats, user_data, user_history', actions)(Home)