import { h, Component } from 'preact';
import { Link } from 'preact-router';
import './style.css';

class FoodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      unit: Object.keys(this.props.item.units)[Object.keys(this.props.item.units).length - 1]
    }
    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.item.name !== "" && this.props.item.name !== prevProps.item.name){
      this.setState({
        ...this.state,
        unit: Object.keys(this.props.item.units)[Object.keys(this.props.item.units).length - 1]
      });
    }
    return this.props;
  }

  handleAmountChange(event) {
    this.setState({
      ...this.state,
      amount: event.target.value.replace(",", ".")
    });
  }
  handleUnitChange(event) {
    this.setState({
      ...this.state,
      unit: event.target.value
    });
  }

  renderUnits(units) {
    return (<select class="form-select select-lg" value={this.state.unit} onChange={this.handleUnitChange}>
      {Object.keys(units).map((unitName) => {
        return (<option key={unitName}>{unitName}</option>)
      })}
    </select>)
  }

  renderBausteineUnits() {
    let unitAmount = this.props.item.units[this.state.unit];
    return "1 Baustein ≙ " + unitAmount + " " + this.state.unit;
  }

  render() {
    return (
      <div id="food_modal" class={(this.props.active ? "active" : "") + " modal modal-sm"}>
        <a href="#close" class="modal-overlay" aria-label="Close"></a>
        <div class="modal-container">
          <div class="modal-header">
            <a class="btn btn-link float-right" onClick={this.props.onClose}><i class="material-icons">close</i></a>
            <div class="modal-title h5">{this.props.item.name}</div>
            <span class="text-gray">{this.renderBausteineUnits()}</span>
          </div>
          <div class="modal-body">
            <div class="content">
              <div class="input-group">
                <input class="form-input input-lg" type="text" placeholder="Menge..." value={this.state.amount} onChange={this.handleAmountChange}/>
                {this.renderUnits(this.props.item.units)}
              </div>
            </div>
          </div>
          <div class="modal-footer">
          
            <Link href="/" class="button_add btn btn-primary" onClick={()=>{this.props.onSubmit(this.state.amount, this.state.unit)}} >Hinzufügen</Link>
          </div>
        </div>
      </div>
    );
  }

}

export default FoodModal;

