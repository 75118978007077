import stats from '../data/stats';
import Fuse from 'fuse.js';

class SearchManager {
  constructor(stats) {
    this.initialize(stats)
  }

  initialize(stats) {
    var options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.4,
      location: 0,
      distance: 15,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: [
        "category",
        "name"
      ]
    };
    this.fuse = new Fuse(stats, options);
  }

  search(value) {
    let result = this.fuse.search(value);
    // result = resul
console.log(result)
    return result;
  }
}

let searchManager = new SearchManager(stats)

export default searchManager